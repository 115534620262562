@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

body, html, #root {
    height: 100%;
}

.root-container {
    background-image: url("../assets/background.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
}

.form-container {
    padding-top: 7%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: 'Open Sans', sans-serif;
    font-size: 22px;
}

.form-label {
    color: white;
}

.form-title {
    margin-bottom: 3%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.form-utm-param-control {
    margin-block: 5px;
}

.invalid-feedback {
    color: white !important;
}

input, button {
    border-radius: 0% !important;
}

.social-bar {
    position: absolute;
    bottom: 5%;
    right: 3%;
}

.social-bar-img {
    width: 50px;
    margin-right: 10px;
}